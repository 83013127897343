var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
!function (t, e) {
  exports = e();
}(exports, function () {
  "use strict";

  var t = 1000,
    e = 60000,
    n = 3600000,
    r = "millisecond",
    i = "second",
    s = "minute",
    u = "hour",
    a = "day",
    o = "week",
    f = "month",
    h = "quarter",
    c = "year",
    d = "date",
    l = "Invalid Date",
    $ = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/,
    y = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g,
    M = {
      name: "en",
      weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
      months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
      ordinal: function (t) {
        var e = ["th", "st", "nd", "rd"],
          n = t % 100;
        return "[" + t + (e[(n - 20) % 10] || e[n] || e[0]) + "]";
      }
    },
    m = function (t, e, n) {
      var r = String(t);
      return !r || r.length >= e ? t : "" + Array(e + 1 - r.length).join(n) + t;
    },
    v = {
      s: m,
      z: function (t) {
        var e = -t.utcOffset(),
          n = Math.abs(e),
          r = Math.floor(n / 60),
          i = n % 60;
        return (e <= 0 ? "+" : "-") + m(r, 2, "0") + ":" + m(i, 2, "0");
      },
      m: function t(e, n) {
        if (e.date() < n.date()) return -t(n, e);
        var r = 12 * (n.year() - e.year()) + (n.month() - e.month()),
          i = e.clone().add(r, f),
          s = n - i < 0,
          u = e.clone().add(r + (s ? -1 : 1), f);
        return +(-(r + (n - i) / (s ? i - u : u - i)) || 0);
      },
      a: function (t) {
        return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
      },
      p: function (t) {
        return {
          M: f,
          y: c,
          w: o,
          d: a,
          D: d,
          h: u,
          m: s,
          s: i,
          ms: r,
          Q: h
        }[t] || String(t || "").toLowerCase().replace(/s$/, "");
      },
      u: function (t) {
        return void 0 === t;
      }
    },
    g = "en",
    D = {};
  D[g] = M;
  var p = function (t) {
      return t instanceof _;
    },
    S = function t(e, n, r) {
      var i;
      if (!e) return g;
      if ("string" == typeof e) {
        var s = e.toLowerCase();
        D[s] && (i = s), n && (D[s] = n, i = s);
        var u = e.split("-");
        if (!i && u.length > 1) return t(u[0]);
      } else {
        var a = e.name;
        D[a] = e, i = a;
      }
      return !r && i && (g = i), i || !r && g;
    },
    w = function (t, e) {
      if (p(t)) return t.clone();
      var n = "object" == typeof e ? e : {};
      return n.date = t, n.args = arguments, new _(n);
    },
    O = v;
  O.l = S, O.i = p, O.w = function (t, e) {
    return w(t, {
      locale: e.$L,
      utc: e.$u,
      x: e.$x,
      $offset: e.$offset
    });
  };
  var _ = function () {
      function M(t) {
        (this || _global).$L = S(t.locale, null, !0), this.parse(t);
      }
      var m = M.prototype;
      return m.parse = function (t) {
        (this || _global).$d = function (t) {
          var e = t.date,
            n = t.utc;
          if (null === e) return new Date(NaN);
          if (O.u(e)) return new Date();
          if (e instanceof Date) return new Date(e);
          if ("string" == typeof e && !/Z$/i.test(e)) {
            var r = e.match($);
            if (r) {
              var i = r[2] - 1 || 0,
                s = (r[7] || "0").substring(0, 3);
              return n ? new Date(Date.UTC(r[1], i, r[3] || 1, r[4] || 0, r[5] || 0, r[6] || 0, s)) : new Date(r[1], i, r[3] || 1, r[4] || 0, r[5] || 0, r[6] || 0, s);
            }
          }
          return new Date(e);
        }(t), (this || _global).$x = t.x || {}, this.init();
      }, m.init = function () {
        var t = (this || _global).$d;
        (this || _global).$y = t.getFullYear(), (this || _global).$M = t.getMonth(), (this || _global).$D = t.getDate(), (this || _global).$W = t.getDay(), (this || _global).$H = t.getHours(), (this || _global).$m = t.getMinutes(), (this || _global).$s = t.getSeconds(), (this || _global).$ms = t.getMilliseconds();
      }, m.$utils = function () {
        return O;
      }, m.isValid = function () {
        return !((this || _global).$d.toString() === l);
      }, m.isSame = function (t, e) {
        var n = w(t);
        return this.startOf(e) <= n && n <= this.endOf(e);
      }, m.isAfter = function (t, e) {
        return w(t) < this.startOf(e);
      }, m.isBefore = function (t, e) {
        return this.endOf(e) < w(t);
      }, m.$g = function (t, e, n) {
        return O.u(t) ? (this || _global)[e] : this.set(n, t);
      }, m.unix = function () {
        return Math.floor(this.valueOf() / 1000);
      }, m.valueOf = function () {
        return (this || _global).$d.getTime();
      }, m.startOf = function (t, e) {
        var n = this || _global,
          r = !!O.u(e) || e,
          h = O.p(t),
          l = function (t, e) {
            var i = O.w(n.$u ? Date.UTC(n.$y, e, t) : new Date(n.$y, e, t), n);
            return r ? i : i.endOf(a);
          },
          $ = function (t, e) {
            return O.w(n.toDate()[t].apply(n.toDate("s"), (r ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(e)), n);
          },
          y = (this || _global).$W,
          M = (this || _global).$M,
          m = (this || _global).$D,
          v = "set" + ((this || _global).$u ? "UTC" : "");
        switch (h) {
          case c:
            return r ? l(1, 0) : l(31, 11);
          case f:
            return r ? l(1, M) : l(0, M + 1);
          case o:
            var g = this.$locale().weekStart || 0,
              D = (y < g ? y + 7 : y) - g;
            return l(r ? m - D : m + (6 - D), M);
          case a:
          case d:
            return $(v + "Hours", 0);
          case u:
            return $(v + "Minutes", 1);
          case s:
            return $(v + "Seconds", 2);
          case i:
            return $(v + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, m.endOf = function (t) {
        return this.startOf(t, !1);
      }, m.$set = function (t, e) {
        var n,
          o = O.p(t),
          h = "set" + ((this || _global).$u ? "UTC" : ""),
          l = (n = {}, n[a] = h + "Date", n[d] = h + "Date", n[f] = h + "Month", n[c] = h + "FullYear", n[u] = h + "Hours", n[s] = h + "Minutes", n[i] = h + "Seconds", n[r] = h + "Milliseconds", n)[o],
          $ = o === a ? (this || _global).$D + (e - (this || _global).$W) : e;
        if (o === f || o === c) {
          var y = this.clone().set(d, 1);
          y.$d[l]($), y.init(), (this || _global).$d = y.set(d, Math.min((this || _global).$D, y.daysInMonth())).$d;
        } else l && (this || _global).$d[l]($);
        return this.init(), this || _global;
      }, m.set = function (t, e) {
        return this.clone().$set(t, e);
      }, m.get = function (t) {
        return this[O.p(t)]();
      }, m.add = function (r, h) {
        var d,
          l = this || _global;
        r = Number(r);
        var $ = O.p(h),
          y = function (t) {
            var e = w(l);
            return O.w(e.date(e.date() + Math.round(t * r)), l);
          };
        if ($ === f) return this.set(f, (this || _global).$M + r);
        if ($ === c) return this.set(c, (this || _global).$y + r);
        if ($ === a) return y(1);
        if ($ === o) return y(7);
        var M = (d = {}, d[s] = e, d[u] = n, d[i] = t, d)[$] || 1,
          m = (this || _global).$d.getTime() + r * M;
        return O.w(m, this || _global);
      }, m.subtract = function (t, e) {
        return this.add(-1 * t, e);
      }, m.format = function (t) {
        var e = this || _global,
          n = this.$locale();
        if (!this.isValid()) return n.invalidDate || l;
        var r = t || "YYYY-MM-DDTHH:mm:ssZ",
          i = O.z(this || _global),
          s = (this || _global).$H,
          u = (this || _global).$m,
          a = (this || _global).$M,
          o = n.weekdays,
          f = n.months,
          h = function (t, n, i, s) {
            return t && (t[n] || t(e, r)) || i[n].slice(0, s);
          },
          c = function (t) {
            return O.s(s % 12 || 12, t, "0");
          },
          d = n.meridiem || function (t, e, n) {
            var r = t < 12 ? "AM" : "PM";
            return n ? r.toLowerCase() : r;
          },
          $ = {
            YY: String((this || _global).$y).slice(-2),
            YYYY: O.s((this || _global).$y, 4, "0"),
            M: a + 1,
            MM: O.s(a + 1, 2, "0"),
            MMM: h(n.monthsShort, a, f, 3),
            MMMM: h(f, a),
            D: (this || _global).$D,
            DD: O.s((this || _global).$D, 2, "0"),
            d: String((this || _global).$W),
            dd: h(n.weekdaysMin, (this || _global).$W, o, 2),
            ddd: h(n.weekdaysShort, (this || _global).$W, o, 3),
            dddd: o[(this || _global).$W],
            H: String(s),
            HH: O.s(s, 2, "0"),
            h: c(1),
            hh: c(2),
            a: d(s, u, !0),
            A: d(s, u, !1),
            m: String(u),
            mm: O.s(u, 2, "0"),
            s: String((this || _global).$s),
            ss: O.s((this || _global).$s, 2, "0"),
            SSS: O.s((this || _global).$ms, 3, "0"),
            Z: i
          };
        return r.replace(y, function (t, e) {
          return e || $[t] || i.replace(":", "");
        });
      }, m.utcOffset = function () {
        return 15 * -Math.round((this || _global).$d.getTimezoneOffset() / 15);
      }, m.diff = function (r, d, l) {
        var $,
          y = O.p(d),
          M = w(r),
          m = (M.utcOffset() - this.utcOffset()) * e,
          v = (this || _global) - M,
          g = O.m(this || _global, M);
        return g = ($ = {}, $[c] = g / 12, $[f] = g, $[h] = g / 3, $[o] = (v - m) / 604800000, $[a] = (v - m) / 86400000, $[u] = v / n, $[s] = v / e, $[i] = v / t, $)[y] || v, l ? g : O.a(g);
      }, m.daysInMonth = function () {
        return this.endOf(f).$D;
      }, m.$locale = function () {
        return D[(this || _global).$L];
      }, m.locale = function (t, e) {
        if (!t) return (this || _global).$L;
        var n = this.clone(),
          r = S(t, e, !0);
        return r && (n.$L = r), n;
      }, m.clone = function () {
        return O.w((this || _global).$d, this || _global);
      }, m.toDate = function () {
        return new Date(this.valueOf());
      }, m.toJSON = function () {
        return this.isValid() ? this.toISOString() : null;
      }, m.toISOString = function () {
        return (this || _global).$d.toISOString();
      }, m.toString = function () {
        return (this || _global).$d.toUTCString();
      }, M;
    }(),
    T = _.prototype;
  return w.prototype = T, [["$ms", r], ["$s", i], ["$m", s], ["$H", u], ["$W", a], ["$M", f], ["$y", c], ["$D", d]].forEach(function (t) {
    T[t[1]] = function (e) {
      return this.$g(e, t[0], t[1]);
    };
  }), w.extend = function (t, e) {
    return t.$i || (t(e, _, w), t.$i = !0), w;
  }, w.locale = S, w.isDayjs = p, w.unix = function (t) {
    return w(1000 * t);
  }, w.en = D[g], w.Ls = D, w.p = {}, w;
});
export default exports;